<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-col :span="24">
        <el-form-item
          label="检查标题："
          prop="name"
          :rules="[{ required: true, message: '请输入标题' }]"
        >
        <el-input
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>
      </el-col>
    <el-col :span="24">
        <el-form-item 
          label="检查部门："
          prop="check_depart_id"
        >
        <BepartmentMulti ref="BepartmentMulti" />
       </el-form-item>
    </el-col>

    <el-col :span="24">
        <el-form-item
          label="检查人员："
          prop="check_ding_userid"
          :rules="[{ required: true, message: '请选择检查人员' }]"
        >
        <el-select
          v-model="formLabelAlign.check_ding_userid"
          filterable
          multiple
          remote
          reserve-keyword
          placeholder="请输入姓名查询"
          :remote-method="getUserAndDept"
          :loading="loading"
          class="form-select"
          >
          <el-option
            v-for="item in userList"
            :key="item.ding_userid"
            :label="item.nickname"
            :value="item.ding_userid">
            <span style="float: left">{{ item.nickname }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.department }}</span>
          </el-option>
        </el-select>
       </el-form-item>
    </el-col>
      <el-col :span="24">
        <div class="time-tys">
            <el-form-item
              label="开始时间："
              prop="begin_time"
              :rules="[{ required: true, message: '请选择开始时间' }]"
            >
              <div>
                <el-date-picker
                  class="time-inp"
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.begin_time"
                  type="date"
                  placeholder="开始时间"
                  @change="DateChange()"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <p>至</p>
            <el-form-item
              label-width="0px"
              label=""
              prop="end_time"
              :rules="[{ required: true, message: '请选择结束时间' }]"
            >
              <div>
                <el-date-picker
                  class="time-inp"
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.end_time"
                  type="date"
                  placeholder="结束时间"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </div>
      </el-col>
      <el-col :span="24">
        <el-form-item
          label="检查说明："
          prop="desc"
          :rules="[{ required: true, message: '请输入简述' }]"
        >
          <el-input
            type="textarea"
            placeholder="请输入简述"
            size="mini"
            class="form-textarea"
            v-model="formLabelAlign.desc"
          ></el-input>
        </el-form-item>
      </el-col>
        
       
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import BepartmentMulti from "../../components/Bepartment_multi.vue";
import { spectionsaveInfo, spectionsave,getUserAndDepartment } from "../../assets/request/api";
// import PersonnelA from "../../components/Personnel_A";
// import Bepartment from "../../components/Bepartment";
export default {
  name: "student_redacy",
  components: {
    BepartmentMulti
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      department_id:[],
      check_ding_userid:[],
      status_list: [],
      formLabelAlign: {
        name: "",
        department_id: "",
        status: "",
        desc: "",
        check_ding_userid: null,
        begin_time: "",
        end_time: "",
        scheme_list:[]
      },
      timeData: [],
      //统计周期结束时间的最低选择时间
      pickerOptions: {},
      userList: [],
      value: [],
      list: [],
      loading: false,
    };
  },
  methods: {
    getUserAndDept(query) {
        if (query !== '') {
          this.loading = true;
          getUserAndDepartment({name:query}).then((res)=>{
            this.loading = false;
            this.userList =res.data;
          })
        } else {
          this.userList = [];
        }
      },
      way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      spectionsaveInfo(froms).then((res) => {
        let { info, status_list } = res.data;
        this.status_list = status_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            name,
            desc,
            check_depart_id,
            status,
            begin_time,
            end_time,
            check_ding_userid,
            check_user_list,
          } = info;
          formLabelAlign.id = id;
          formLabelAlign.desc = desc;
          formLabelAlign.name = name;
          formLabelAlign.status = status;
          formLabelAlign.check_ding_userid = check_ding_userid;
          formLabelAlign.begin_time = begin_time;
          formLabelAlign.end_time = end_time;
          this.userList = check_user_list;
          let item = check_depart_id.split(",");
          console.log(item,'chekc_dept')
          this.$refs.BepartmentMulti.way_show(item);
          this.DateChange(1);
          this.timeData = [begin_time, end_time];
        }
      });
    },
    on_timeData() {
      let { timeData, formLabelAlign } = this;
      if (timeData == null) {
        formLabelAlign.begin_time = "";
        formLabelAlign.end_time = "";
      } else {
        formLabelAlign.begin_time = timeData[0];
        formLabelAlign.end_time = timeData[1];
      }
    },
    // on_delete(index) {
    //   this.formLabelAlign.check_ding_userid.splice(index, 1);
    // },
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.begin_time
          );
        },
      };
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          // console.log(formLabelAlign.check_ding_userid,'chjekc')
          let department_id = this.$refs.BepartmentMulti.way_pitch();
          if (!department_id) {
            this.$message({
              message: "请选择被检部门",
              type: "warning",
            });
          }
          formLabelAlign.check_depart_id = department_id;

          spectionsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 460px;
}
.pop-inp {
  width: 460px;
}
.form-select{
  width: 460px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.form-textarea {
  width:460px;
  margin-bottom: 10px;
}
.time-tys {
  display: flex;
  p {
    margin: 0 5px;
    padding-top:7px
  }
  .time-inp {
    width: 220px;
  }
}
</style>