<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form  :model="formLabelAlign" class="from">
            <el-form-item label="操作人：" label-width="80px" style="width: 320px;">
              <el-input
                placeholder="请输入姓名"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="时间选择：" label-width="90px" style="width: 550px;">
              <div>
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.bengin_time"
                  type="date"
                  placeholder="开始日期"
                  @change="DateChange()"
                >
                </el-date-picker>
                至
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.end_time"
                  type="date"
                  placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个数据</span>
            <span class="span2">已选择 {{ more_List.length }} 个数据</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <el-button @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
          <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              :index="indexMethod"
            ></el-table-column>
            <el-table-column prop="title" label="标题">
              <template slot-scope="scope">
                  <el-link
                    class="list_sty"
                    type="primary"
                    @click="on_particulars(scope.row.id)"
                    >{{ scope.row.title }}</el-link
                  >
                </template>
            </el-table-column>
            <el-table-column prop="check_users" label="检查人">
            </el-table-column>
            <el-table-column prop="check_departments" label="检查部门">
             </el-table-column>
            <el-table-column prop="add_time" label="检查时间">
              <template slot-scope="scope">
                {{scope.row.begin_time}}至{{scope.row.end_time}}
              </template>>
            </el-table-column>

            <el-table-column prop="name" label="操作">
              <template slot-scope="scope">
                <el-link v-if="scope.row.status == 0" @click="on_pop_addplus(scope.row.id)">修改</el-link>
                <el-link
                  v-if="scope.row.status == 0"
                  @click="on_assess(scope.row.id, 1)"
                  class="list_sty"
                  type="primary"
                >检查通知</el-link>
                <el-link
                  v-else-if="scope.row.status == 1"
                  @click="on_assess(scope.row.id, 1)"
                  class="list_sty"
                  disabled
                >已通知</el-link>
                <el-link
                  v-else-if="scope.row.status == 2"
                  class="list_sty"
                  @click="on_particulars(scope.row.id)"
                  type="primary"
                >查看结果</el-link>             
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      show-close
      :title="rowitem.name"
      :visible.sync="drawer"
      width="30%"
    >
      <div class="drawer">
        <div class="drawer-more">
          <div>
            <p class="p1">管控人：</p>
            <p class="p2">{{ rowitem.nickname }}</p>
          </div>

          <div>
            <p class="p1">管控时间：</p>
            <p class="p2">{{ rowitem.add_time }}</p>
          </div>

          <div>
            <p class="p1">管控点状态：</p>
            <el-tag
              :type="rowitem.form_is_normal == 1 ? 'success' : 'danger'"
              >{{ rowitem.is_normal }}</el-tag
            >
          </div>

          <div v-if="rowitem.form_images">
            <p class="p1">图片：</p>
            <div class="imgsList">
              <el-image
                :z-index="4000"
                v-for="(item, index) in com_imgList"
                :key="index"
                :src="item"
                :preview-src-list="[item]"
              ></el-image>
            </div>
          </div>

          <div v-if="rowitem.form_vedio">
            <p class="p1">视频：</p>
            <video
              class="upload-sty"
              controls="controls"
              :src="rowitem.form_vedio"
            ></video>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <Randomredact
        @way_tableData="way_tableData"
        v-if="dialog_show"
        ref="Randomredact"
        :refbool.sync="dialog_show"
      />
    </el-dialog>

<!-- 审批弹窗 -->
<el-dialog title="检查通知" :visible.sync="assess_pop" show-close center>
      <el-form label-width="150px" ref="formName" :model="assess_Data">
        <el-row :gutter="20">          
            <el-form-item
          label="检查人员："
          prop="check_ding_userid"
          :rules="[{ required: true, message: '请选择检查人员' }]"
        >
          <el-select
            v-model="assess_Data.check_ding_userid"
            filterable
            multiple
            remote
            @change="assessDataChange"
            placeholder="请输入姓名查询"
            :remote-method="getUserAndDept"
            :loading="loading"
            class="form-select"
            >
            <el-option
              v-for="item in userList"
              :key="item.ding_userid"
              :label="item.nickname"
              :value="item.ding_userid">
              <span style="float: left">{{ item.nickname }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.department }}</span>
            </el-option>
          </el-select>
          </el-form-item>

          <el-form-item label="检查日期：" prop="time">
            <el-date-picker
              v-model="assRangetime"
              type="daterange"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item 
              label="检查部门："
              prop="check_depart_id"
            >
            <BepartmentMulti ref="BepartmentMulti"  @changeData = "departChange" />
          </el-form-item>
          <el-form-item
              label="任务表单："
              prop="scheme_id"
              :rules="[{ required: true, message: '请选择任务表单' }]"
            >
              <el-select
                class="form-inp"
                v-model="assess_Data.scheme_id"
                placeholder="任务表单："
                @change="assessDataChange"

              >
                <el-option
                  v-for="item in scheme_list"
                  :key="item.value"
                  :label="item.task_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
        </el-row>

        <el-row>
          <div class="block">
          <el-timeline> 
            <el-timeline-item
              v-for="(item, index) in flowList"
              :key="index"
              :timestamp="item.activityId"
              placement="top"
            style="padding-bottom: none;">
              <el-card>

                <h4>{{item.activityName}} : {{ item.activityValues }}</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        </el-row>

      </el-form>

      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary"
          >发布</el-button>
        <el-button @click="assess_pop = false">返回</el-button>
      </div>
    
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import Randomredact from "../../components/view_redact/Random_redact";
import BepartmentMulti from "../../components/Bepartment_multi.vue";
import { spectionindex, spectiondelete,Workschemeindex,spectiontaskSend ,getUserAndDepartment,spectionsaveInfo,spectionForcastFlow } from "../../assets/request/api";
export default {
  name: "Special_inspection",
  components: { Randomredact,BepartmentMulti },
  mixins: [table_minin],
  data() {
    return {
      title: "",
      dialog_show: false,
      formLabelAlign: {
        bengin_time: "",
        end_time: "",
        name: "",
        status: "",
      },
      rowitem: "",
      drawer: false,
      assess_pop: false,
      assess_Data: {
        source_ids: null,
        scheme_id:null,
        check_ding_userid:null,
        check_depart_id:null,
      },
      assRangetime:["2023-5-11","2023-5-20"],
      scheme_list:[],
      userList:[],
      loading:false,
      flowList:[]
    };
  },
  created() {
    this.url = spectionindex;
    this.delete_Url = spectiondelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    getUserAndDept(query) {
        if (query !== '') {
          this.loading = true;
          getUserAndDepartment({name:query}).then((res)=>{
            this.loading = false;
            this.userList =res.data;
          })
        } else {
          this.userList = [];
        }
      },
    on_particulars(id) {
      this.$router.push({
        path: "/assess/spection_particulars",
        query: {
          id: id,
        },
      });
    },
    departChange(){
      let { assess_Data } = this;
      let department_id = this.$refs.BepartmentMulti.way_pitch();
      assess_Data.check_depart_id = department_id;
      this.assessDataChange();
    },
    on_assess(id, type) {
      this.assess_Data.source_ids = id;
      this.assess_pop = true;
      Workschemeindex({ task_code:"spection_recorder" }).then((res) => {
        let {data} = res.data;
        this.scheme_list = data;
        this.assess_Data.scheme_id = data[0].id;
      }).then(()=>spectionsaveInfo({id}).then((res) => {
        let {info} =res.data;
        let{check_ding_userid,check_user_list,check_depart_id,begin_time,end_time }=info;
        this.assRangetime= [begin_time,end_time];
        this.assess_Data.check_ding_userid = check_ding_userid;
        this.userList = check_user_list
        let item = check_depart_id.split(",");
        console.log(this.$refs.BepartmentMulti,'this.$refs')
          this.$refs.BepartmentMulti.way_show(item);
      })).then(()=>{
        this.assessDataChange()
      });
    },

    assessChange(event) {
      let events = event[event.length - 1];
      this.assess_Data.cate_id = events;
      this.assessDataChange()
    },
    assessDataChange(){
      spectionForcastFlow({...this.assess_Data}).then((res)=>{
        let flowList = res.data;
        this.flowList = flowList
      })
    },
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
       
        if (valid) {
          let { assess_Data } = this;
          let department_id = this.$refs.BepartmentMulti.way_pitch();
          assess_Data.begin_time = this.assRangetime[0];
          assess_Data.end_time = this.assRangetime[1];
          if (!department_id) {
            this.$message({
              message: "请选择被检部门",
              type: "warning",
            });
          }
          assess_Data.check_depart_id = department_id;

          let From_Data = JSON.parse(JSON.stringify(assess_Data));
          console.log(From_Data,'From_Data')
          spectiontaskSend(From_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "success",
              });
              this.assess_pop = false;
              this.way_tableData();
            }
          });

        } else {
          return false;
        }
      });
    },
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.bengin_time
          );
        },
      };
    },
    on_skipaddplus(id) {
      this.$router.push({
        path: "/assess/Special_templatealter",
        query: {
          id: id,
        },
      });
    },
    on_template(id) {
      this.$router.push({
        path: "/assess/Special_templatealter",
        query: {
          id,
          page_type: 2,
        },
      });
    },
    on_examine(id) {
      this.$router.push({
        path: "/security/Risk_manparticulars",
        query: {
          id,
        },
      });
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.Randomredact.way_show(id);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.drawer-more {
  & > div {
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    font-size: 16px;
    align-items: center;
  }
  .p1 {
    width: 100px;
    margin-right: 10px;
  }
}
.imgsList {
  display: flex;
  & > div {
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.list_sty {
  margin-left: 10px;
}
</style>
